import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { RedirectGuard } from './core/security/redirect-guard';

const routes: Routes = [
  {
    path: 'glamqueen', //OLD
    loadChildren: () =>
      import('./pages/glamqueen/glamqueen.module').then(
        (m) => m.GlamqueenModule
      )
  },
  {
    path: 'royal',
    loadChildren: () =>
      import('./pages/glamqueen/glamqueen.module').then(
        (m) => m.GlamqueenModule
      )
  },
  {
    path: 'convite',
    loadChildren: () =>
      import('./pages/glamgirls/glamgirls.module').then(
        (m) => m.GlamgirlsModule
      )
  },
  {
    path: 'glamdiva', //OLD
    loadChildren: () =>
      import('./pages/glamdivas/glamdivas.module').then(
        (m) => m.GlamdivasModule
      )
  },
  {
    path: 'ambassador',
    loadChildren: () =>
      import('./pages/glamdivas/glamdivas.module').then(
        (m) => m.GlamdivasModule
      )
  },
  {
    path: 'promocoes/growth-slot-1',
    loadChildren: () =>
      import('./pages/growth-slot-1/growth-slot-1.module').then(
        (m) => m.GrowthSlot1Module
      )
  },
  {
    path: 'promocoes/growth-slot-2',
    loadChildren: () =>
      import('./pages/growth-slot-2/growth-slot-2.module').then(
        (m) => m.GrowthSlot2Module
      )
  },
  {
    path: 'promocoes/growth-slot-3',
    loadChildren: () =>
      import('./pages/growth-slot-3/growth-slot-3.module').then(
        (m) => m.GrowthSlot3Module
      )
  },
  {
    path: 'promocoes/growth-slot-4',
    loadChildren: () =>
      import('./pages/growth-slot-4/growth-slot-4.module').then(
        (m) => m.GrowthSlot4Module
      )
  },
  {
    path: 'promocoes/growth-slot-5',
    loadChildren: () =>
      import('./pages/growth-slot-5/growth-slot-5.module').then(
        (m) => m.GrowthSlot5Module
      )
  },
  {
    path: 'promocoes/social/assinaturas',
    loadChildren: () =>
      import('./pages/social-slot-1/social-slot-1.module').then(
        (m) => m.SocialSlot1Module
      )
  },
  {
    path: 'promocoes/social-slot-2',
    loadChildren: () =>
      import('./pages/social-slot-2/social-slot-2.module').then(
        (m) => m.SocialSlot2Module
      )
  },
  {
    path: 'promocoes/social-slot-3',
    loadChildren: () =>
      import('./pages/social-slot-3/social-slot-3.module').then(
        (m) => m.SocialSlot3Module
      )
  },
  {
    path: 'promocoes/glampartners', //OLD
    loadChildren: () =>
      import('./pages/glampartners/glampartners.module').then(
        (m) => m.GlampartnersModule
      )
  },
  {
    path: 'promocoes/partner',
    loadChildren: () =>
      import('./pages/glampartners/glampartners.module').then(
        (m) => m.GlampartnersModule
      )
  },
  {
    path: 'promocoes/bflu',
    loadChildren: () =>
      import('./pages/glampartners/glampartners.module').then(
        (m) => m.GlampartnersModule
      )
  },
  {
    path: 'promocoes/assinaturas',
    loadChildren: () =>
      import('./pages/promotion-signatures/promotion-signatures.module').then(
        (m) => m.SignaturesModule
      )
  },
  {
    path: 'promocoes/glambox',
    loadChildren: () =>
      import('./pages/promotion-glambox/promotion-glambox.module').then(
        (m) => m.PromotionGlamboxModule
      )
  },
  {
    path: 'promocoes/glambag',
    loadChildren: () =>
      import('./pages/promotion-glambag/promotion-glambag.module').then(
        (m) => m.PromotionGlambagModule
      )
  },
  {
    path: 'promocoes/glampass',
    loadChildren: () =>
      import('./pages/promotion-glampass/promotion-glampass.module').then(
        (m) => m.PromotionGlampassModule
      )
  },
  {
    path: 'promocoes/glamcombo',
    loadChildren: () =>
      import('./pages/promotion-glamcombo/promotion-glamcombo.module').then(
        (m) => m.PromotionGlamcomboModule
      )
  },
  {
    path: 'edicao/glambox',
    loadChildren: () =>
      import('./pages/glambox-edition/glambox-edition.module').then(
        (m) => m.GlamboxEditionModule
      )
  },
  {
    path: 'edicao',
    loadChildren: () =>
      import('./pages/edition-signatures/edition-signatures.module').then(
        (m) => m.SignaturesEditionsModule
      )
  },
  {
    path: 'clube/livelo',
    loadChildren: () =>
      import('./pages/partnership-livelo/partnership-livelo.module').then(
        (m) => m.PartnershipLiveloModule
      )
  },
  {
    path: 'promocoes/:urlPagePath',
    loadChildren: () =>
      import('./pages/growth-dynamic-slots/growth-dynamic-slots.module').then(
        (m) => m.GrowthDynamicSlotsModule
      )
  },
  // { path: '404', component: WrongRouteComponent },
  {
    path: '**',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      copyPath: false,
      externalUrl: 'https://glambox.com.br/clube'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
