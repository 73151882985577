import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'landing-pages-frontend';

  constructor(
    @Inject(PLATFORM_ID) private platform: object,
    private router: ActivatedRoute
  ) {
    if (isPlatformBrowser(this.platform)) {
      this.router.queryParams.subscribe((params) => {
        sessionStorage.clear();
        Object.keys(params).forEach((key) => {
          sessionStorage.setItem(key, params[key]);
        });
      });
    }
  }
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}
