// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://apiglamapp.glambox.com.br/',
  hasura: {
    endpoint: 'https://hasura-products-36m4jjtkna-rj.a.run.app/v1/graphql',
    adminSecret: 'b171ab4d722455b03648ed462b41f8c2'
  },
  firebase: {
    apiKey: 'AIzaSyADyiL4mMWKp0zqVbX_lZy-oYChSpwoNzM',
    authDomain: 'b4astage-cb80a.firebaseapp.com',
    projectId: 'b4astage-cb80a',
    storageBucket: 'b4astage-cb80a.appspot.com',
    messagingSenderId: '925318835135',
    appId: '1:925318835135:web:f578064576b444f12dcf0a'
  },
  elasticSearch: {
    url: 'https://a2bb4426e59548a78607bb5a78c4e8cc.us-central1.gcp.cloud.es.io:443',
    credential: 'TzNYS2RZY0J0M3dXQS1YS3VsY006Vkx6aG9mMDJTSzZGOEt4Vk9ZcjVKZw=='
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
