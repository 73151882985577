import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(@Inject(PLATFORM_ID) private platform: object) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean {
    if (isPlatformBrowser(this.platform)) {
      let url = route.data['externalUrl'];

      if (route.data['copyPath']) url += route['_routerState'].url;
      window.location.href = url;
      return true;
    }
  }
}
