import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RedirectGuard } from './core/security/redirect-guard';
import { AngularConnectModule } from '@infrab4a/connect-angular';
import { Shops } from '@infrab4a/connect';

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:5000'
    }),
    AngularConnectModule.initializeApp(Shops.GLAMSHOP, {
      hasura: {
        endpoint: environment.hasura.endpoint,
        credentials: { adminSecret: environment.hasura.adminSecret }
      },
      firebase: environment.firebase,
      elasticSearch: environment.elasticSearch,
      backendUrl: environment.apiUrl
    })
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   // Ativar o Cacheamento e a tranferencia de estados para evitar duplicacao de SSR
    //   useClass: BrowserStateInterceptor,
    //   // useClass: HttpGlamInterceptor,
    //   multi: true
    // },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    RedirectGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
